import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

export let A0ACCESSTOKEN: string|null = null;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const CheckAuthenticationStatus: React.FC<React.PropsWithChildren> = ({children,}) => 
{
    const [appRenderGo, setAppRenderGo] = React.useState<boolean>(false);
    const {isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

    //Extracts access token from auth0 and signals to load main app
    const ExtractToken = async () => {

        //Grab token
        getAccessTokenSilently().then(token => {
          A0ACCESSTOKEN = token;
          setAppRenderGo(true);
        });
        
    };

    //If auth0 loading finished and we have not yet rendered
    if (!isLoading && !appRenderGo)
    {
        //If we have successfully authenticated, call token extractor
        if (isAuthenticated)
            ExtractToken();
        else //Otherwise redirect to auth0
            loginWithRedirect();
    }

    if (appRenderGo) //If we are ready to render main app, return children
        return <React.Fragment>{children}</React.Fragment>;
    else //Otherwise show auth
    {
      return (
        <div>
          Authorizing...
        </div>
      );
    }
};

root.render(
  <Auth0Provider
    domain='aimer-dev.au.auth0.com'
    clientId='ADqiA4h1SK6dVWXdYTAgUssnO35r2t14'
    authorizationParams={{
        audience: 'dev.aimer.com',
        redirect_uri: window.location.origin
    }}
    >
      <CheckAuthenticationStatus>
        <App />
      </CheckAuthenticationStatus>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();