/*

    File read wrapper with done callback and additional useful funcs

*/
import { md5 } from 'js-md5';

export class IFileRead {

    static Md5HashB64Str(b64Str: string) : string
    {
        return md5.base64(b64Str);
    }

    fileName: string;
    mimeType: string;
    data: string|null;
    errorStr: string|null;

    constructor (fileName: string, mimeType: string, data: string|null, errorStr: string|null)
    {
        this.fileName = fileName;
        this.mimeType = mimeType;
        this.data = data;
        this.errorStr = errorStr;
    }

    //Splits file data into segments
    GetSegmentedB64Data(segmentSize: number) : Array<string>
    {
        if (this.data == null)
            return [];

        const returnB64Segments: Array<string> = [];
        let currentSegment: string = '';

        for (let i = 0; i < this.data.length; i++)
        {
            const currentChar: string = this.data[i];
            
            //If we have reached the segment size, add and create a new one
            if (currentSegment.length >= segmentSize)
            {
                returnB64Segments.push(currentSegment);
                currentSegment = '';
            }
            
            //Add char to segment
            currentSegment += currentChar;
        }

        //Add the final segment
        returnB64Segments.push(currentSegment);

        return returnB64Segments;
    }

    DataMd5Hash() : string
    {
        return IFileRead.Md5HashB64Str(this.data!);
    }
};

interface IFileDoneCallback {
    (readFile: IFileRead): void
};

interface IFinishedCallback {
    (hasErrors: boolean): void
};

export default class FileUploadHandler
{
    totalFiles: number;
    maxFileSize_Bytes: number;
    readMode: 'b64'|'text';
    FileDoneCallback?: IFileDoneCallback;
    readFiles: Array<IFileRead>;
    errorsOccured: boolean;
    FinishedCallback?: IFinishedCallback;

    //Adds a file to the array and handles callbacks
    private FileSet(file: File, fileData: string|null, errorStr: string|null = null) : void
    {
        //If text mode, remove ascii encode chars
        if (this.readMode == 'text')
            fileData = fileData?.replaceAll('ï»¿', '')!;
        else if (this.readMode == 'b64')
        {
            fileData = fileData?.replaceAll('data:application/octet-stream;base64,', '')!;
        }

        //Create and add
        const newRead: IFileRead = new IFileRead(
            file.name,
            file.type,
            fileData,
            errorStr
        );
        this.readFiles.push(newRead);

        //If we have a callback
        if (this.FileDoneCallback)
            this.FileDoneCallback(newRead);

        //If we have finished with all files and have a final callback
        if (this.FinishedCallback != null && this.readFiles.length == this.totalFiles)
            this.FinishedCallback(this.errorsOccured);
    }

    //Begins individual file read
    private ReadFile(file: File) : void
    {
        const fileReader = new FileReader();
        if (this.readMode ?? 'b64' == 'b64')
            fileReader.readAsDataURL(file);
        else
            fileReader.readAsText(file);
        
        fileReader.onload = () => {
            this.FileSet(file, fileReader.result as string);
        };

        fileReader.onerror = () => {
            this.errorsOccured = true;
            this.FileSet(file, fileReader.result as string, fileReader.error?.message);
        };
    }

    constructor(
        maxFileSize_Bytes: number, 
        fileList: FileList, 
        readMode: 'b64'|'text',
        FileDoneCallback?: IFileDoneCallback,
        FinishedCallback?: IFinishedCallback
        )
    {
        //Init
        this.totalFiles = (fileList ?? []).length;
        this.maxFileSize_Bytes = maxFileSize_Bytes;
        this.readMode = readMode;
        this.FileDoneCallback = FileDoneCallback;
        this.readFiles = [];
        this.errorsOccured = false;
        this.FinishedCallback = FinishedCallback;
        
        //If invalid file list
        if (fileList == null || fileList.length < 1)
            return;

        //Loop through filelist
        for (let i = 0; i < fileList.length; i++)
            this.ReadFile(fileList[i]);
    }

    FileData() : Array<IFileRead>
    {
        return this.readFiles;
    }
}