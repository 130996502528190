/*

    Handles loading and sending of data

*/

import { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { A0ACCESSTOKEN } from '.';

export interface DataContainer {
    url: string;
    requestMethod: string;
    dataBody?: any;
    Success?: {(dataCallback?: any): void},
    Fail?: {(): void};
};

interface DataSync_Props {
    todo: Array<DataContainer>;
    Finished: Function;
};

let completedN: number = 0;

export default function DataSync(props: DataSync_Props): React.JSX.Element
{
    const [queriesStarted, setQueriesStarted] = useState<boolean>(false);
    const [updateFlip, setUpdateFlip] = useState<boolean>(false);

    const ProgressCheck = async () => {

        //Increment  completed n
        ++completedN;

        //If we are now finished callback
        if (completedN >= props.todo.length)
        {
            props.Finished();
        }

        setUpdateFlip(!updateFlip);
    }

    //On first mount setup all queries
    useEffect(() => {

        completedN = 0;

        props.todo.forEach( async (q) => {

            fetch(q.url, {
                method: q.requestMethod,
                headers: {
                    "authorization" : "Bearer " + A0ACCESSTOKEN,
                    'content-type': 'application/json'
                },
                body: q.dataBody,
            }).then(response => {

                //1
                if (response.ok)
                {
                    if (q.Success)
                    {
                        response.json().then(jData => {
                            q.Success!(jData);
                        });
                    }
                }
                else
                {
                    if (q.Fail)
                        q.Fail!();
                }

                ProgressCheck();
            });
        });

        setQueriesStarted(true);
    }, []);

    return (
        <div style={{width: '100%'}}>
            <ProgressBar 
                now={Math.ceil((completedN / props.todo.length) * 100)}
            />
        </div>
    );
}