//todo: Fix files being stored and re-sent again and again

import React, { useRef, useState } from 'react';
import './App.css';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FilePart, FileType } from './types';
import DataSync, { DataContainer } from './DataSync';
import FileUploadHandler, { IFileRead } from './FileUploadHandler';
import { v4 as uuidv4 } from 'uuid';

//todo: replace with env var
const BASEURL: string = '/api/upload';

export default function App() 
{
  const uploadIRef = useRef<HTMLInputElement>(null);

  //Retrieved server vals
  const [maxRequestSizeB, SetMaxRequestSizeB] = useState<number|null>(null);
  const [sendTimeoutS, SetSendTimeoutB] = useState<number|null>(null);
  const [allowedFormats, setAllowedFormats] = useState<Array<FileType>|null>(null);
  const [allowedFormatsStr, setAllowedFormatsStr] = useState<string>('');

  const [fileInputChange, setFileInputChange] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<string|React.JSX.Element>('');
  const [filesToUpload, setFilesToUpload] = useState<Array<IFileRead>>([]);

  //Page start load queries
  const PAGELOADQUERIES = [
    {
      url: BASEURL + '/MaxRequestSize_Bytes',
      requestMethod: 'GET',
      Success: (maxRequestSize: number) => {
        SetMaxRequestSizeB(maxRequestSize);
      }
    },
    {
      url: BASEURL + '/FileTimeout_Seconds',
      requestMethod: 'GET',
      Success: (timeout: number) => {
        SetSendTimeoutB(timeout);
      }
    },
    {
      url: BASEURL + '/AllowedFileFormats',
      requestMethod: 'GET',
      Success: (fileTypes: Array<FileType>) => {
        //Build file types string and set
  
        let fileTypesStr: string = '';
        fileTypes.forEach((ft, index) => {
          fileTypesStr += (index > 0 ? ',' : '') + ft.fextension;
        });
        
        setAllowedFormats(fileTypes);
        setAllowedFormatsStr(fileTypesStr);
      }
    }
  ];

  //Loading/sending values
  const [queriesTodo, setQueriesTodo] = useState<Array<DataContainer>|null>(PAGELOADQUERIES);

  const HandleUploadStart = () => {
    setUploadStatus('Grabbing Files');
  
    const uploadHandler: FileUploadHandler = new FileUploadHandler(
      maxRequestSizeB!,
      uploadIRef.current?.files!,
      'b64',
      (newFile: IFileRead) => {
  
        //If no error
        if (newFile.errorStr == null)
        {
          filesToUpload.push(newFile);
          setFilesToUpload([...filesToUpload]);
        }
        else
          console.log('Error uploading file! (%s)', newFile.errorStr);
      },
      (errors: boolean) => {
  
        //If we had errors
        if (errors)
          setUploadStatus(<b color='red'>An error occured grabbing one or more files!</b>);
        else
        {
          //If success
          setUploadStatus('Getting things together');
  
          //Setup new queries todo based on files
          const newSendQueries: Array<DataContainer> = [];
          filesToUpload.forEach(ftu => {
  
            //Generate new guid
            const requestGuid: string = uuidv4();
  
            //Split file data into appropriate sizes
            const fileDataSegments: Array<string> = ftu.GetSegmentedB64Data(maxRequestSizeB!);
  
            //Build new post queries from data segments
            fileDataSegments.forEach((fds, index) => {
              //Create new file part
              const fPart: FilePart = {
                FileTypeId: 1,
                UploadRequestGuid: requestGuid,
                PartIndex: index,
                TotalPartCount: fileDataSegments.length,
                FilePartMD5Checksum: ftu.DataMd5Hash(),
                B64FilePart: fds
              };
  
              newSendQueries.push({
                url: BASEURL + '/PutFile',
                dataBody: JSON.stringify(fPart),
                requestMethod: 'PUT'
              });
  
            });
          });
  
          //Begin upload queries
          setUploadStatus('Uploading To Server');
          setQueriesTodo(newSendQueries);
  
          // Clear the filesToUpload array after initiating the upload
          setFilesToUpload([]);
        }
      }
    );
  };
  

  //Set main controls
  let mainControls: React.JSX.Element;
  if (queriesTodo != null)
  {
    mainControls = (
      <DataSync
        todo={queriesTodo}
        Finished={() => {
          setQueriesTodo(null);
          setUploadStatus('');
        }}
      />
    );
  }
  else
  {
    mainControls = (
      <React.Fragment>
        <h4><b>Vision File Upload</b></h4>
        <Form.Group controlId="formFileLg" className="mb-3">
          <Form.Label>Select Files</Form.Label>
          <Form.Control 
            ref={uploadIRef}
            type="file" 
            size="lg" 
            multiple
            accept={allowedFormatsStr}
            disabled={uploadStatus != ''}
            onChange={() => setFileInputChange(!fileInputChange)}
          />
        </Form.Group>
        <Row>
          <Col md={8}>
            <label>{(uploadStatus && typeof uploadStatus == 'string' ? uploadStatus + '...' : uploadStatus)}</label>
          </Col>
          <Col md={4}>
            <Button 
            variant='primary float-end' 
            size='lg' 
            style={{marginTop: '15px'}} 
            disabled={uploadStatus != '' || uploadIRef.current?.files?.length! < 1} 
            onClick={HandleUploadStart}
          >
            Begin Upload
          </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
      <Container>
        {mainControls}
      </Container>
  );
}